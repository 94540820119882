<template>
    <div ref="component" class="InlineField__Component"
        @mouseover="hover(true)" @mouseleave="hover(false)"
        :class="{'has-value': inputVal !== null,
            'invalid': required && !inputVal, 'read-only': readOnly}">
        <label>
            <div class="InlineField__Component__Placeholder">
                <span
                    class="InlineField__Component__Placeholder__Text">{{label}}</span>
                <span class="InlineField__Component__Placeholder__Icon">
                <b-icon
                    icon="pencil"
                    size="is-small">
                </b-icon></span>
            </div>
                <textarea
                    @focus="focus(true)"
                    @blur="focus(false)"
                    :readonly="readOnly"
                    :required="required"
                    @input="$emit('onChange')"
                    dir="rtl"
                    v-model="inputVal" >
                </textarea>
        </label>
    </div>
</template>

<script>
export default {
    name: 'FieldInlineTextArea',
    props: ['value', 'label', 'required', 'readOnly'],
    computed: {
    inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val !== '' ? val : null);
            },
        },
    },
    data() {
        return {
            isFocused: false,
        };
    },
    methods: {
        hover(isHover) {
            if (isHover) {
                this.$refs.component.classList.add('is-hover');
            } else {
                this.$refs.component.classList.remove('is-hover');
            }
        },
        focus(isFocused) {
            if (isFocused) {
                this.$refs.component.classList.add('is-focused');
            } else {
                this.$refs.component.classList.remove('is-focused');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.InlineField__Component {
    padding: 5px 0;

    &:not(.read-only) {
        &.invalid .InlineField__Component__Placeholder,
        &.is-hover .InlineField__Component__Placeholder,
        &.is-focused .InlineField__Component__Placeholder {
            transform: translateY(0px);
        }
        &.is-hover input,
        &.is-focused input {
            border-bottom: 1px solid #00a4bd;
        }
        &.is-hover ::v-deep .vs__actions {
            opacity: 1;
        }
    }
    &.has-value .InlineField__Component__Placeholder {
        transform: translateY(0px);
    }

    .InlineField__Component__Placeholder__Icon {
        color: #00a4bd;
        opacity: 0;
        top: 20px;
        position: relative;
    }
    &.read-only {
        .InlineField__Component__Placeholder__Icon {
            display: none;
        }
    }

    &.is-hover:not(.is-focused):not(.has-value) .InlineField__Component__Placeholder__Icon {
        opacity: 1;
    }

    .InlineField__Component__Placeholder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: translateY(20px);
        transition: transform linear .1s;

        .InlineField__Component__Placeholder__Text {
            color: #287994;
            font-size: 14px;
            font-weight: 500;
        }
    }

    &.invalid label ::v-deep textarea {
        background-color: #e9f5ff;
        padding: 7px 0;
    }
    label {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        position: relative;
    }
    &.read-only {
        label {
            cursor: default;
        }
    }
    textarea {
        min-height: 100px;
        max-height: 100px;
        max-width: 100%;
        min-width: 100%;
        border: none;
        border-bottom: 1px solid transparent;
        width: 100%;
        border-radius: 0;
        transition: all linear 0.1s;
        font-size: 16px;

        &:focus {
            outline: none;
            cursor: text;
        }
    }
}
</style>
